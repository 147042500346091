import React from "react";
import "./services.css";

function Services() {
  return (
    <section className="services">
      <div className="service">
        <h3>Academic Support</h3>
        <p>
          We provide a range of academic support services, including tutoring,
          study groups, and access to a comprehensive library for all students.
        </p>
      </div>
      <div className="service">
        <h3>Career Development</h3>
        <p>
          Our dedicated career counselors offer guidance on career planning,
          resume building, interview preparation, and internship opportunities.
        </p>
      </div>
      <div className="service">
        <h3>Student Engagement</h3>
        <p>
          We believe in a holistic education. Our college offers a variety of
          clubs, organizations, and events to foster personal growth and
          leadership skills.
        </p>
      </div>
    </section>
  );
}

export default Services;
