import React from "react";
import Slideshow from "./slideshow/Slideshow";
import About from "./about/About";
import Gallery from "./gallery/Gallery";
import VisitUs from "./visitus/VisitUs";

function Home() {
  return (
    <div>
      <Slideshow />
      <About />
      <Gallery />
      <VisitUs />
    </div>
  );
}

export default Home;
