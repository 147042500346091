import React from "react";
import "./campusLife.css";

function CampusLife() {
  return (
    <section className="campus-life">
      <div className="campus-content">
        <h2>Campus Life</h2>
        <p>
          At Example College, we believe in providing a vibrant and enriching
          campus experience for our students. Here are some highlights of life
          on our campus:
        </p>
        <ul>
          <li>Engage in various clubs and student organizations.</li>
          <li>Participate in sports and fitness programs.</li>
          <li>Attend cultural events and performances.</li>
          <li>Enjoy a range of dining options and amenities.</li>
          <li>Access to modern recreational facilities.</li>
        </ul>
      </div>
      <div className="campus-image">
        <img src="campus-life.jpg" alt="Campus Life" />
      </div>
    </section>
  );
}

export default CampusLife;
