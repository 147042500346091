import React from "react";
import "./courses.css";

function Courses() {
  return (
    <section className="courses">
      <div className="course">
        <h3>Computer Science</h3>
        <p>
          Our Computer Science program equips students with the skills and
          knowledge to excel in the rapidly evolving tech industry.
        </p>
      </div>
      <div className="course">
        <h3>Business Administration</h3>
        <p>
          The Business Administration program prepares students for leadership
          roles in the corporate world, emphasizing critical thinking and
          strategic decision-making.
        </p>
      </div>
      <div className="course">
        <h3>Environmental Science</h3>
        <p>
          Environmental Science students engage in hands-on learning to address
          pressing environmental issues and contribute to a sustainable future.
        </p>
      </div>
    </section>
  );
}

export default Courses;
