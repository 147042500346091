import React from "react";
import "./footer.css";
import { AiOutlineMail } from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";

function Footer() {
  return (
    <div className="footer">
      <div className="contact-info">
        <h3>Contact Information</h3>
        <p>
          <AiOutlineMail /> Email: info@examplecollege.edu
        </p>
        <p>
          <BsFillTelephoneFill /> Phone: +123-456-7890
        </p>
      </div>
      <div className="address">
        <h3>Visit Us</h3>
        <p>123 College Street</p>
        <p>City, State 12345</p>
      </div>
    </div>
  );
}

export default Footer;
