// import React from "react";

// function Admission() {
//   return <div>Admission</div>;
// }

// export default Admission;

import React from "react";
import "./admissionProcess.css";

function Admission() {
  return (
    <section className="admission-process">
      <div className="admission-content">
        <h2>Admission Process</h2>
        <p>
          We make the admission process at Example College as seamless as
          possible. Here's a step-by-step guide to help you through it:
        </p>
        <ol>
          <li>Fill out the online application form on our website.</li>
          <li>Submit the required documents, including transcripts and ID.</li>
          <li>
            Attend an interview session with our admissions committee (if
            required).
          </li>
          <li>Receive your admission decision within 2-4 weeks.</li>
          <li>
            Upon acceptance, complete the enrollment process and pay the tuition
            fee.
          </li>
        </ol>
      </div>
    </section>
  );
}

export default Admission;
