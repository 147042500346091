import React from "react";
import "./header.css";
import logo1 from "../../assets/logo/college.png";
import logo2 from "../../assets/logo/sanstha.png";

function Header() {
  return (
    <div className="toplogo">
      <img className="logo1" src={logo1} alt="" />
      <h1>Kokan Ratna KalaMahavidyalay Bharne</h1>
      <img className="logo2" src={logo2} alt="" />
    </div>
  );
}

export default Header;
