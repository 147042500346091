import React from "react";
import ImageSlider, { Slide } from "react-auto-image-slider";
import pic1 from "../../../assets/example/college1.jpg";
import pic2 from "../../../assets/example/college2.jpg";
import pic3 from "../../../assets/example/college3.jpg";
import "./slideshow.css";

function slideshow() {
  return (
    <div className="slider">
      <ImageSlider effectDelay={500} autoPlayDelay={2000}>
        <Slide className="slide">
          <img alt="img1" src={pic1} />
        </Slide>
        <Slide>
          <img alt="img2" src={pic2} />
        </Slide>
        <Slide>
          <img alt="img3" src={pic3} />
        </Slide>
      </ImageSlider>
    </div>
  );
}

export default slideshow;
