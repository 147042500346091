import React from "react";
import "./gallery.css";
import img1 from "../../../assets/example/college1.jpg";
import img2 from "../../../assets/example/college2.jpg";
import img3 from "../../../assets/example/college3.jpg";

function Gallery() {
  return (
    <div className="gallery">
      <div className="heading">
        <h3>Gallery</h3>
      </div>
      <div className="gallery_content">
        <div className="article1">
          <img className="img" src={img1} alt="College Event 1" />
          <p className="description">
            Students participating in a cultural event celebrating diversity.
          </p>
        </div>
        <div className="article1">
          <img className="img" src={img2} alt="College Event 2" />
          <p className="description">
            A group of students presenting their research at a science fair.
          </p>
        </div>
        <div className="article1">
          <img className="img" src={img3} alt="College Event 3" />
          <p className="description">
            The college campus adorned with festive decorations during a holiday
            celebration.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
