import React from "react";
import "./navbar.css";

function Navbar() {
  return (
    <div className="nav">
      <ul className="nav_list">
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <a href="/about">About</a>
        </li>
        <li>
          <a href="/admission">Admission</a>
        </li>
        <li>
          <a href="/courses">Courses</a>
        </li>
        <li>
          <a href="/services">Services</a>
        </li>
        <li>
          <a href="/campuslife">Campus life</a>
        </li>
        <li>
          <a href="/contact">Contact</a>
        </li>
      </ul>
    </div>
  );
}

export default Navbar;
