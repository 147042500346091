import React from "react";
import "./visitus.css";

function VisitUs() {
  return (
    <div className="visitus">
      <div className="header">
        <h3>Visit Us</h3>
      </div>
      <div className="visitus_content">
        <div className="text">
          <h4>Address :</h4>
          <p>
            AT-Post Bharne, <br></br>
            Navbharat High School compound <br></br>
            Tal-Khed , Dist-Ratnagiri <br></br>
            <br></br>
            Kumbi Shiksham prasarak Sanstha Khed , Mumbai<br></br>
            Kokan Ratna Kala Mahavidyalay Bharne, Ratnagiri, Maharashtra{" "}
            <br></br>
            <br></br>
            <br></br>
          </p>
          <h4>Working Hours :</h4>
          <p>
            Monday-Saturday: 9:15-05:15 Hrs <br></br>
            The college remains closed on Sundays and <br></br> Second and
            Fourth Monday of every month.
          </p>
        </div>
        <div className="map">
          <img src="" alt="" />
        </div>
      </div>
    </div>
  );
}

export default VisitUs;
