import React from "react";
import "./about.css";
import clg1 from "../../../assets/example/college1.jpg";

function About() {
  return (
    <div className="about">
      <div className="about_clg_info">
        <h3>About KRKMB</h3>
        <span>
          The principal objective of the Kumbi Shiksham Prasarak Sanstha Khed is
          to "carry on activities for the benefits and development of residents
          of rural India by applying all suitable means available, focusing on
          issues like health, literacy, non-formal education, social awareness,
          agriculture, technical education, etc., on its own or by joining hands
          or by helping like-minded NGOs."
          <br />
          Establishment of Kokan Ratna KalaMahavidyalay Bharne in a typical
          rural sector aligns with the philosophy of Kumbi Shiksham Prasarak
          Sanstha Khed. KRKMB has provided good infrastructure, modern
          equipment, advanced laboratories, experienced and dedicated teachers,
          and a modified syllabus to suit the changing environment. The college
          also offers hands-on workshops by collaborating with foreign
          universities and arranges pre-entrance preparatory workshops. All of
          this is provided at a reasonable cost, with arrangements for loan
          facilities with subsidized interest rates. Hence, the institute has
          emerged as a premier institution in the eyes of the government and
          businessmen within a couple of years.
        </span>
      </div>
      <div className="about_clg_img">
        <img src={clg1} alt="" />
      </div>
    </div>
  );
}

export default About;
